import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteProps } from 'react-router';
import { Redirect, Route } from "react-router-dom";
import { RightsHelper } from "../../helper/RightsHelper";
import { AlertMessage, UserState } from "../../types";

export type ProtectedRouteProps = {
    user: UserState;
    redirectTo: string;
    rights: string[];
} & RouteProps;

const ProtectedRoute = ({ user, redirectTo, rights, ...routeProps }: ProtectedRouteProps) => {
    const { t } = useTranslation();

    if (RightsHelper.isGranted(user, rights)) {
        return <Route {...routeProps} />;
    } else {
        const alert: AlertMessage = {
            message: t('alert.not_granted'),
            level: 'danger'
        };

        return <Redirect to={{
            pathname: redirectTo,
            state: {
                alert: alert
            }
        }}/>;
    }
}

const mapStateToProps = (state: { user: UserState }) => ({
    user: state.user,
});

export default connect(mapStateToProps)(ProtectedRoute);
