import React from "react";
import { AppUrls, Roles } from "../../constants";
import { config } from "../../config";
import { ReactComponent as OpenDataLogo } from "../../assets/icon/open_data.svg";
import { ReactComponent as SignalLogo } from "../../assets/icon/alert-diamond.svg";
import { ReactComponent as CalendarLogo } from "../../assets/icon/calendar-3.svg";
import { ReactComponent as ConstructionLogo } from "../../assets/icon/usineicon.svg";
import { ReactComponent as WifiMonitorLogo } from "../../assets/icon/wifi-monitor.svg";
import { SzIcon } from "@suezenv/react-theme-components";

type MenuItemsProps = {
    history: any,
    location: any, 
    t: any, // translation
    showOperatorMenu: any
    setShowOperatorMenu: any
}

export type MenuItem = {
    className?: string,
    key: string,
    title: string,
    icon: JSX.Element,
    handleClick: () => void,
    category: string,
    active?: boolean,
    type: 'item' | 'submenu' | 'external_link',
    roles?: string[],
}

export const MenuItems = (props: MenuItemsProps): MenuItem[] => { 
    const { history, location, showOperatorMenu, setShowOperatorMenu, t } = props

    const locationHasOneOfUrl = (urls: string[]): boolean => {
        let found: boolean = false
        urls.forEach((url: string) => {
            if (location.pathname === AppUrls.HOME && url === AppUrls.HOME) {
                found = true;
            } else if (location.pathname.includes(url) && url !== AppUrls.HOME) {
                found = true;
            }
        });
        return found;
    }

    return [
    {
        key: 'dashboard',
        icon: <SzIcon icon="layout-dashboard" variant={"line"}/>,
        title: t('navigation.dashboard'),
        handleClick: () => history.push(AppUrls.HOME),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.HOME, AppUrls.CARTO]),
        category: 'hypervision'
    },
    {
        roles: [Roles.ROLE_POWERBI_REPORT_TRENDS],
        key: 'powerBi',
        icon: <SzIcon icon="analytics-bars-3d" variant={"line"}/>,
        title: t('navigation.Reporting'),
        handleClick: () => history.push(AppUrls.POWER_BI_WORKSPACE),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.POWER_BI_WORKSPACE]),
        category: 'hypervision'
    },
    {
        roles: [Roles.ROLE_THINGS_BOARD],
        key: 'thingsboardDashboard',
        icon: <SzIcon icon="analytics-bars-3d" variant={"line"}/>,
        title: t('navigation.thingsboard.dashboard'),
        handleClick: () => history.push(AppUrls.THINGS_BOARD_WORKSPACE),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.THINGS_BOARD_WORKSPACE]),
        category: 'hypervision'
    },
    {
        roles: [Roles.ROLE_SIGNAL_NOTIFY, Roles.ROLE_SIGNAL_OPERATOR],
        key: "signal",
        icon: <div style={{ fontSize: "1.5rem", lineHeight: "1.5" }}>
            <SignalLogo className="icon-big color-icon-primary"/></div>,
        title: t('navigation.signal'),
        handleClick: () => history.push(AppUrls.SIGNAL_VIEW),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.SIGNAL_DETAIL, AppUrls.SIGNAL_VIEW, AppUrls.SIGNAL_CREATE]),
        category: 'tools'
    },
    {
        roles: [Roles.ROLE_REQUEST, Roles.ROLE_REQUEST_OPERATOR],
        key: "request",
        icon: <SzIcon icon="messages-bubble-edit" variant="line"/>,
        title: t('navigation.request'),
        handleClick: () => history.push(AppUrls.REQUEST_VIEW),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.REQUEST_VIEW, AppUrls.REQUEST_CREATE, AppUrls.REQUEST_CHOICE]),
        category: 'tools'
    },
    {
        roles: [Roles.ROLE_PUBLIC_SPACE_PLANNER],
        key: "publicWork",
        icon: <SzIcon icon="professions-man-construction-1" variant="line"/>,
        title: t('navigation.PublicWork'),
        handleClick: () => history.push(AppUrls.PUBLIC_WORK_LIST),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.PUBLIC_WORK_LIST,AppUrls.PUBLIC_WORK_NEW, AppUrls.PUBLIC_WORK_DETAIL]),
        category: 'tools'
    },
    {
        roles: [Roles.ROLE_VA_GEST_DOC],
        key: "document",
        icon: <SzIcon icon="folder-file-1" variant="line"/>,
        title: t('navigation.document'),
        handleClick: () => {
            const portalMayan = window.open(`${config.mayanUrl}/cabinets/cabinets`, '_blank');
            portalMayan?.focus();
        },
        type: 'external_link',
        category: 'tools'
    },
    {
        roles: [Roles.ROLE_SUPER_ADMIN_PORTAL_OPEN_API, Roles.ROLE_PORTAL_VDM],
        key: "openApi",
        icon: <div style={{ fontSize: "1.5rem", lineHeight: "1.5" }}><OpenDataLogo
            className="icon-big color-icon-primary"/></div>,
        title: t('navigation.openApi'),
        handleClick: () => {
            const portalOpenApiWindow = window.open(config.portalOpenApiUrl, '_blank');
            portalOpenApiWindow?.focus();
        },
        type: 'external_link',
        category: 'tools'
    },
    {
        roles: [Roles.ROLE_SUPER_ADMIN_PORTAL_THINGS_BOARD, Roles.ROLE_THINGS_BOARD],
        key: "thingsboardPortal",
        icon: <div style={{ fontSize: "1.5rem", lineHeight: "1.5" }}><WifiMonitorLogo
            className="icon-big"/></div>,
        title: t('navigation.thingsboard.portal'),
        handleClick: () => {
            const portalThingsboardWindow = window.open(config.portalThingsboardUrl, '_blank');
            portalThingsboardWindow?.focus();
        },
        type: 'external_link',
        category: 'tools'
    },
    {
        roles: [Roles.ROLE_INDUSTRIAL_SITE],
        key: "industrialSite", 
        icon: <div style={{ fontSize: "1.5rem", lineHeight: "1.5" }}>
            <ConstructionLogo className="icon-big color-icon-primary"/></div>,
        title: t('navigation.industrialSite'),
        handleClick: () => history.push(AppUrls.INDUSTRIAL_SITE_VIEW),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.INDUSTRIAL_SITE_VIEW]),
        category: 'administration'
    },
    {
        roles: [Roles.ROLE_CALENDAR], 
        key: "calendar",
        icon: <div style={{ fontSize: "1.5rem", lineHeight: "1.5" }}>
            <CalendarLogo className="icon-big color-icon-primary"/></div>,
        title: t('navigation.calendar'),
        handleClick: () => history.push(AppUrls.CALENDAR_VIEW),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.CALENDAR_VIEW]),
        category: 'administration'
    },
    {
        roles: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE],
        key: "contractManagement",
        icon: <SzIcon icon="navigation-menu-4" variant="line"/>,
        title: t('navigation.contractManagement'),
        handleClick: () => history.push(AppUrls.ADMIN_CONTRACTS),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.ADMIN_CONTRACTS]),
        category: 'administration'
    },
    {
        roles: [Roles.ROLE_SUPER_ADMIN_CONTRACT, Roles.ROLE_SUPER_ADMIN_ORGANIZATION],
        key: "territorialManagement",
        icon: <SzIcon icon="single-neutral-actions-setting" variant="line"/>,
        title: t('navigation.territorialManagement'),
        handleClick: () => history.push(AppUrls.ADMIN_GESTION_TERRITORIALE),
        type: 'item',
        active: locationHasOneOfUrl([AppUrls.ADMIN_GESTION_TERRITORIALE]),
        category: 'administration'
    },
    {
        className: "nav-dashboard-module-dropdown-button",
        key: "portals",
        icon: <SzIcon icon="money-wallet-open" variant="line"/>,
        title: t('navigation.portals'),
        handleClick: () => setShowOperatorMenu(!showOperatorMenu),
        type: 'submenu',
        active: showOperatorMenu,
        category: 'hypervision'
    }
]}
