import { createInstance } from '@datapunt/matomo-tracker-react'
import { config } from '../../config';

export const instance = createInstance({
    urlBase: config.matomoUrlBase,
    siteId: config.matomoSiteId,
    // userId: 'UID76903202', // optional, default value: `undefined`.
    // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
    // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
    disabled: document.cookie.split(";").some((item) => item.includes("matomoOptOut=true")) || config.matomoDisabled , // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        // seconds: 10 // optional, default value: `15
    },
    // linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        // disableCookies: true,
        setSecureCookie: config.matomoSetSecureCookie,
        setRequestMethod: 'POST'
    }
})