import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import './SzNewSideMenu.scss';
import { SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import { useLocation } from "react-router-dom";
import { MenuItem } from "components/model/MenuItems";

type Categories = {
    [category: string]: MenuItem[]
}

const SzNewSideMenu = (props: {items: MenuItem[], showMenu: boolean, setShowMenu: (show: boolean) => void}) => {
    const { items, showMenu, setShowMenu } = props;
    const { t } = useTranslation();
    const location = useLocation();
    const [categories, setCategories] = useState<Categories>({});
    const [isOpen, setOpen] = useState<boolean>(true)
    const [activeItem, setActiveItem] = useState<string>("");

    const onItemClick = (item: MenuItem) => {
        item.handleClick();
        findActiveItem();
        closeMobileMenu()
    }

    //submenu activation is prior to item activation, so we need to test the type of each items before activating them.
    const findActiveItem = () => {
        let tmpActive: string = "";
        let tmpActiveType: string = "";
        items.forEach((item: MenuItem) => {
            if ('undefined' !== typeof item.active)
            {
                if (item.active && (tmpActiveType === "item" || tmpActiveType === "")) {
                    tmpActive = item.key;
                    tmpActiveType = item.type;
                }
            }
        })
        setActiveItem(tmpActive);
    }

    const closeMobileMenu = () => {
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        findActiveItem();
    }, [location.pathname, items])

    useEffect(() => {
        let tmpCategories: Categories = {};
        items.forEach((item: MenuItem) => {
            let category: MenuItem[] = [];
            if (tmpCategories.hasOwnProperty(item.category)) {
                category = tmpCategories[item.category].slice(0);
            }
            category.push(item);
            tmpCategories[item.category] = category;
        });
        setCategories(tmpCategories);
    }, [items]);

    return <aside className={classnames("flex-shrink-0", "sz-side-menu", isOpen ? showMenu ? "open sz-aside-enter-done" : "open sz-aside-enter-done d-none d-sm-block" : showMenu ? "sz-aside-exit-done d-none d-sm-block": "sz-aside-exit-done")}>
        <nav>
            <ul className={"pl-0 mb-0 d-none d-sm-block"}>
                <li className={"sz-side-menu--toggle-open-btn cursor-pointer d-flex justify-content-between align-items-center"}
                    onClick={() => setOpen(!isOpen)}>
                    <SzTypographie className={"sz-aside__item--hidden-close mb-0"} variant={"caption"}
                                   weight={"bold"}>{t('menu.close_menu')}</SzTypographie>
                    <SzIcon icon={isOpen ? 'arrow-rectangle-left' : 'arrow-rectangle-right'} variant={'line'}/>
                </li>
            </ul>
            {Object.entries(categories).map((entry: [string, MenuItem[]], index: number) => {
                const label = entry[0];
                const items = entry[1];
                return <ul key={index} className={"pl-0 mb-0 sz-side-menu--item-category"}>
                    <SzTypographie className={"mb-3 pl-4 sz-aside__item--hidden-close"} variant={"caption"} color={"inactive"}>{t(`menu.category.${label}`)}</SzTypographie>
                    {items.map((item: MenuItem, index: number) => {
                        const className = classnames(item.type, item.className, "cursor-pointer", item.key === activeItem? "active" : "");
                        return <li onClick={() => onItemClick(item)} key={index} className={className}>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <span className='sz-aside__item--hidden-close flex-grow-0'>{item.title}</span>
                                {item.icon && item.icon}
                            </div>
                        </li>
                    })}
                </ul>
            })}
        </nav>
    </aside>
}

export default SzNewSideMenu;
